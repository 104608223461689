<template>
  <div id="app-m">
    <img class="img" src="@/assets/image/aboutUs.png" alt="" />
    <div class="line9" v-show="btn">
      <div class="line9-ctx">
        Copyright © 2003-2024 <br />XJAI100.COM All Rights Reserved
        湘ICP备2023007847号-3
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showList: false, //导航列表显示 | 隐藏 == true | false
      headerList: [], //header列表
      currentfirstitem: 0, //header当前项
      // showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      activeClass: true, //样式切换
      // showTop: true, //滚动到100距离隐藏顶部
      showLogin: 1, //登录框
      isShadow: false,
      btn: true,
    };
  },
  watch: {
    //考虑浏览器前进后退
    $route: {
      handler(to) {
        const compArr = ["/", "/help", "/news", "/join", "/info", "/newsinfo"];
        let idx = compArr.findIndex((item) => {
          return item === to.path;
        });
        if (idx !== 0) {
          this.activeClass = false;
        } else {
          this.activeClass = true;
        }
        this.currentfirstitem = idx === 6 ? 3 : idx;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getRoute();
    this.getData();
  },
  mounted() {
    // 手机端滑动页面,关闭导航列表
    window.addEventListener("touchmove", () => {
      this.showList = false;
    });
    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop )
      const routerArr = ["/", "/help", "/news", "/join", "/info"];
      if (scrollTop > 600 && this.$route.path === "/") {
        this.activeClass = false;
        this.isShadow = true;
      }
      if (scrollTop < 600 && this.$route.path === "/") {
        this.activeClass = true;
        this.isShadow = false;
      }
      if (scrollTop > 377 && this.$route.path === "/news") {
        this.isShadow = true;
      }
      if (scrollTop < 377 && this.$route.path === "/news") {
        this.isShadow = false;
      }
    });
  },
  methods: {
    changeShowList() {
      this.showList = !this.showList;
    },
    getRoute() {
      let url = window.location.href;
      let lastName = url.split("/");

      if (lastName[lastName.length - 1] === "share") {
        this.btn = false;
      } else {
        this.btn = true;
      }
      console.log();
    },
    //获取数据
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      // let a = currentWidth > 1920 ? '@2x' : ''
      // this.showme = currentWidth > 1920 ? 0 : 0;
      this.headerList = [
        {
          id: 0,
          title: "首页",
          img: "11.png",
        },
        {
          id: 1,
          title: "使用帮助",
          img: "12.png",
        },
        {
          id: 2,
          title: "新闻中心",
          img: "13.png",
        },
        {
          id: 3,
          title: "招商加盟",
          img: "14.png",
        },
        {
          id: 4,
          title: "了解企业",
          img: "15.png",
        },
      ];
    },
    //路由
    go(param) {
      this.showList = false;
      const routerArr = ["/", "/help", "/news", "/join", "/info"];
      if (routerArr[param] === this.$route.path) return;

      // 当点击新闻页的时候，提醒分页跳转到首页
      if (param === 3) {
        this.$bus.$emit("back");
      }

      if (param !== 0) {
        this.activeClass = false;
      } else {
        this.activeClass = true;
      }
      this.$router.push({
        path: routerArr[param],
      });
      this.currentfirstitem = param;
    },

    //登录
    login(p) {
      //
      if (!p) {
        let url = window.location.origin;
        if (url.match(RegExp(/xiaoguobx/g))) {
          url = window.location.origin + "/student/";
        } else {
          url = window.location.origin + "/s/";
        }
        window.location.href = url;
        // window.open(`${this.baseURL}/student/#/login`);
      } else {
        let url = window.location.origin;
        if (url.match(RegExp(/xiaoguobx/g))) {
          url = window.location.origin + "/teacher/";
        } else {
          url = window.location.origin + "/t/";
        }
        window.location.href = url;
        // window.open(`${this.baseURL}/teacher/#/`);
      }
    },
  },
};
</script>

<style scoped>
@import "../style_mobile/Header.css";
#app-m{
  margin-top: 0;
}
#app-m .img {
  width: 100%;
}

.header-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}
</style>

<template>
  <div id="Header">

   

    <div v-show="btn" :class="[
        activeClass ? 'header' : 'headers',
        isShadow ? 'header-shadow' : '',
      ]">
      <div :class="[activeClass ? 'header-1' : 'header-2']" v-if="showTop">
        <div class="header-1-1" v-if="activeClass">
          <!-- <img src="@/assets/firstpage/图层 1495 拷贝.png" alt="" v-if="!this.showme" /> -->
          <img src="@/assets/firstpage/组 3@2x.png" style="height: 50px; width: 200px; padding-top: 15px"
            v-if="!this.showme" />
        </div>

        <!-- <div class="header-1-1" v-if="!activeClass">
          <img src="@/assets/firstpage/logo-02.svg" style="height:66px; width: 172px; padding-top: 5px;"
            v-if="!this.showme" />
        </div> -->
        <div class="header-1-2">
          <div v-for="(item, index) in headerList" :key="item.id" @click="go(index)"
            :class="{ active: currentfirstitem == index }">
            <span v-if="index !== 1">
              {{ item.title }}
            </span>
            <div v-else ref="download">
              <div @click="download(1)">下载</div>
            </div>
            <!-- <div v-else @mouseover="showDownload" @mouseleave="hideDownload"
              :class="{ active: isShowDownload ? true : false }">
              下载
              <div ref="download"
                style="background:#fff; transition: all 0.5s; border-radius:5px; overflow:hidden; height:0px">
                <div
                  style="width:100%; height:50px; line-height:50px; color:#000; border-bottom:1px solid rgba(0,0,0,0.05);"
                  @click="download(1)">
                  浏览器下载
                </div>
                <div style="width:100%; height:50px; line-height:50px; color:#000;" @click="download(2)">
                  Scratch下载
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div ref="login" class="header-1-3" @mouseover="mouseoverLogin" @mouseleave="mouseleaveLogin">
          登录
          <div class="header-1-3-item">
            <div class="student" @click="login(0)">我是学生</div>
            <div class="teacher" @click="login(1)">我是老师</div>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="msg" v-show="showMsg">
      <div class="line8" v-show="btn">
        <div class="line8Img">
          <img src="@/assets/firstpage/组 151@2x.png" />
        </div>
        <div class="line8-ctx">
          <div class="line8-2">
            <div class="title">关于我们</div>
            <div class="content">
              湖南勇智珈科技有限公司以“AI赋能教育，数据驱动学习、自主智能系统”为目标，依托于浙大专家团队联合开发的小珈享学3.0自适应学习系统，公司立足于全国前沿教育，致力于基于未来科技教育的现代化解决方案，多项技术和产品获得国家专利，并取得了国家教育部、信息产业部等多部门的资质认证。
            </div>
          </div>
          <div class="line8-3">
            <div class="title">联系方式</div>
            <div class="phone">
              <img src="@/assets/firstpage/图层 43.png" alt="" style="height: 18px" />
              <!-- <img src="@/assets/firstpage/图层 43@2x.png" alt="" v-if="this.showme" /> -->
              <span>电话：</span>
              <span>15616705276</span>
            </div>
            <div class="email">
              <img src="@/assets/firstpage/图层 44.png" alt="" style="height: 14px" />
              <!-- <img src="@/assets/firstpage/图层 44@2x.png" alt="" v-if="this.showme" /> -->
              <span>邮箱：</span>
              <span>guoshiedu@qq.com</span>
            </div>
            <div class="address">
              <img src="@/assets/firstpage/图层 45.png" alt="" style="height: 20px" />
              <!-- <img src="@/assets/firstpage/图层 45@2x.png" alt="" v-if="this.showme" /> -->
              <span>地址：</span>
              <span>长沙市芙蓉区古汉国际广场1栋11楼</span>
            </div>
          </div>
          <div class="line8-1-2">
            <img src="@/assets/firstpage/erweima.jpg" alt="" style="height: 150px; margin-top: -20px" />
            <!-- <p>关注公众号</p> -->
          </div>
        </div>
      </div>
    </div>
    <div v-show="!showMsg">
      <div class="text_box">共享优质学习，提高分数找小珈！</div>
    </div>
    <!-- <div class="line3">
      <div class="line3-ctx">
        <div class="line3-3">
            <img src="@/assets/firstpage/组底部1.png" alt=""/>
        </div>
       
      </div>
     
    </div> -->
    <!-- Copyright © 2003-2024 MOMAAI.CN All Rights Reserved -->
    <!-- 湘ICP备2023007847号-2 POWERED BY 技术支持 -->
    <div class="line9" v-show="btn">
      <div class="line9-ctx">
        Copyright © 2003-2024 XJAI100.COM All Rights Reserved
        <a class="a_targt" target="_blank" href="http://beian.miit.gov.cn">湘ICP备2023007847号-3 POWERED BY 技术支持</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      headerList: [], //header列表
      currentfirstitem: 0, //header当前项
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      activeClass: true, //样式切换
      showTop: true, //滚动到100距离隐藏顶部
      showLogin: 1, //登录框
      isShadow: false,
      btn: true,
      isShowDownload: false,
      testHeight: "",
      showMsg: true,
    };
  },
  watch: {
    //考虑浏览器前进后退   就在这里判断诶
    // help .join 要
    $route: {
      handler(to) {
        if (to.path === "/scratchdownload") {
          this.isShowDownload = true;
        } else {
          this.isShowDownload = false;
        }
        const compArr = ["/", "/browserdownload", "/help", "/join"];
        let idx = compArr.findIndex((item) => {
          return item === to.path;
        });
        if (idx !== 0) {
          this.activeClass = true;
        } else {
          this.activeClass = true;
        }
        this.currentfirstitem = idx === 6 ? 3 : idx;

        const compArrMsg = ["/help", "/join"];

        this.showMsg = compArrMsg.indexOf(to.path) == -1 ? false : true;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getRoute();
    this.getData();
  },
  mounted() {
    let that = this;

    // setTimeout(() => {
    //   let doms = document.getElementsByClassName("line10");
    //   that.testHeight = doms[0].height + "px";
    // }, 100);

    // window.onresize = () => {
    //   return (() => {
    //     let doms = document.getElementsByClassName("line10");
    //     that.testHeight = doms[0].height + "px";
    //   })();
    // };

    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const routerArr = [
        "/",
        "/browserdownload",
        "/help",
        "/news",
        "/join",
        "/info",
        "/aboutUs",
      ];
      if (scrollTop > 600 && this.$route.path === "/") {
        this.activeClass = false;
        this.isShadow = true;
      }
      if (scrollTop < 600 && this.$route.path === "/") {
        this.activeClass = true;
        this.isShadow = false;
      }
      if (scrollTop > 377 && this.$route.path === "/news") {
        this.isShadow = true;
      }
      if (scrollTop < 377 && this.$route.path === "/news") {
        this.isShadow = false;
      }
    });
  },
  methods: {
    getRoute() {
      let url = window.location.href;
      let lastName = url.split("/");

      // 如果打开的为share页面,则关闭页头页脚,添加title,meta
      if (lastName[lastName.length - 1] === "share") {
        this.btn = false;
      } else {
        this.btn = true;
      }
    },
    //获取数据
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      // let a = currentWidth > 1920 ? '@2x' : ''
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.headerList = [
        {
          id: 0,
          title: "首页",
        },
        {
          id: 1,
          title: "下载",
        },
        // {
        //     id:1,
        //     title:'客户端下载'
        // },
        {
          id: 2,
          title: "使用帮助",
        },
        // {
        //   id: 3,
        //   title: "加盟咨询",
        // },
        {
          id: 3,
          title: "合作",
        },
        {
          id: 4,
          title: "关于我们",
        },
      ];
    },
    //路由
    go(param) {
      console.log(param);
      if (param !== 1) {
        const routerArr = ["/", "", "/help", "/join", "/aboutUs"];
        console.log(488);
        if (routerArr[param] === this.$route.path) return;
        console.log(151515);
        // 当点击新闻页的时候，提醒分页跳转到首页
        if (param === 3) {
          this.$bus.$emit("back");
        }

        if (param !== 0) {
          this.activeClass = false;
        } else {
          this.activeClass = true;
        }
        console.log(routerArr[param]);
        this.$router.push({
          path: routerArr[param],
        });
        this.currentfirstitem = param;
      } else {
        // console.log("okkkkkkkkkkk", param);
      }
    },
    download(value) {
      if (this.$route.path !== "/browserdownload" && value === 1) {
        this.$router.push("/browserdownload");
      }
      if (this.$route.path !== "/scratchdownload" && value === 2) {
        this.$router.push("/scratchdownload");
      }
    },
    mouseoverLogin() {
      this.showLogin = 1;
      this.$refs.login.style.height = "177px";
    },
    mouseleaveLogin() {
      this.showLogin = 0;
      this.$refs.login.style.height = "77px";
    },
    showDownload() {
      this.$refs.download[0].style.height = "100px";
    },
    hideDownload() {
      this.$refs.download[0].style.height = "0px";
    },
    //登录
    login(p) {
      //
      if (!p) {
        let url = window.location.origin + "/s/";
        window.location.href = url;
        // window.open(`${this.baseURL}/student/#/login`);
      } else {
        let url = window.location.origin + "/t/";
        window.location.href = url;
        // window.open(`${this.baseURL}/teacher/#/`);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/Header.styl';

.header-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}

.a_targt {
  color: #949494;
  text-decoration: none;
}

.header-1-2 div {
  width: 100px;
  text-align: center;
}

.header-1-2>div:nth-child(1) {
  position: relative;
  right: -25px;
}

.txt_big {
  display: flex;
  flex-direction: column;
  font-size: 5px;
  width: 250px;
  height: 100px;
}

.txt_small {
  height: 10px;
}

.line10 {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.text_box {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 42%;
  height: 50px;
  text-align: center;
  font-family: SourceHanSerifCN-Medium;
}

</style>
